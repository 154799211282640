import React, { useState } from 'react'
import logo from '../assets/total_company_logo_2.png'
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import { useContext } from 'react';

function Footer() {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScrollToFooter = () => {
    const footer = document.getElementById('roadmap');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
      setActive(false);
    }
    navigate('/');
  };

  //homelanding

  const handleScrollToAbout = () => {
    const footer = document.getElementById('about us');
    navigate('/');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
      setActive(false);
    }
  };


  const toggleContact = () => {
    if (window.location.pathname !== '/') {
      localStorage.setItem("openModalOnHomepage", "true");
      navigate("/");
    } else {
      const homelanding = document.getElementById('homelanding');
  
      if (homelanding) {
        homelanding.scrollIntoView({ behavior: 'smooth' });
        setActive(true); // Set the active state to true
      }
    }
  };
  const handleTermsOfServiceClick = () => {
    navigate('/termsofservice');
  };

  const { language } = useContext(LanguageContext);

  const t = translations[language];
  
  
  return (
    <footer className="bg-neutral-50 dark:bg-neutral-900">
      <div className="flex flex-col sm:flex-row justify-center pt-3">
        <div className="flex flex-col items-center mb-4 sm:mb-0 sm:mr-14">
          <button onClick={toggleContact}>
            <h1 className="text-base dark:text-white">{t.contactUs}</h1>
          </button>
          <button>
            <h1 className="text-base dark:text-white">
              <a
                className="text-base text-neutral-950 dark:text-white decoration-0 no-underline"
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/totalai0fficial?igsh=ODI1emRwMTJoNnJw&utm_source=qr"
              >
                Instagram
              </a>
            </h1>
          </button>
          <button>
            <h1 className="text-base dark:text-white">
              <a
                className="text-base text-neutral-950 dark:text-white decoration-0 no-underline"
                rel="noreferrer"
                target="_blank"
                href="https://www.tiktok.com/@totalaiofficial"
              >
                TikTok
              </a>
            </h1>
          </button>
        </div>
  
        <div className="flex justify-center mb-4 sm:mb-3 sm:ml-5">
          <button onClick={handleScrollToTop}>
            <img src={logo} alt="" className="h-20 dark:invert" />
          </button>
        </div>
  
        <div className="flex flex-col items-center sm:ml-14">
          <button>
            <h1 onClick={handleScrollToAbout} className="text-base dark:text-white">
              {t.aboutUs}
            </h1>
          </button>
          <button onClick={handleTermsOfServiceClick}>
            <h1 className="text-base dark:text-white">{t.termsOfService}</h1>
          </button>
          <button>
            <h1 onClick={handleScrollToFooter} className="text-base dark:text-white">
              {t.road}
            </h1>
          </button>
        </div>
      </div>
      <div className="justify-center flex">
        <h1 className="text-xs dark:text-white">CopyRight © 2023 by Total</h1>
      </div>
    </footer>
  );
  
}

export default Footer

export const translations = {
  English: {
    aboutUs: "About Us",
    contactUs:"Contact Us",
    termsOfService:"Terms Of Service",
    road:"RoadMap",
  },
  Arabic: {
    aboutUs: "معلومات عنا",
    contactUs:"اتصل بنا",
    termsOfService: "شروط الخدمة",
    road:"خارطة الطريق",
  },
};