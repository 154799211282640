import React from 'react'
import elon from '../assets/70ADB235-623B-4C75-A734-B4510560F312.jpg'
import joker from '../assets/4AFA819A-ED99-4AB9-9AF2-3E0B09028E92.jpg'
import ghibli from '../assets/842CBBA3-AC2B-434E-8AB8-D085324F977D.jpg'
import bats from '../assets/9EEEC832-7E31-4681-A4F2-E39E38337051.jpg'
import ai from '../assets/B57B9DD6-BBEA-4207-9701-6EB2B4D216FD.jpg'
import forest from '../assets/5C5580C2-361A-4A4F-B29C-84DA0DE3D332.jpg'

function TotalAifeats() {
  return (
    <div className=''>

    </div>
  )
}

export default TotalAifeats