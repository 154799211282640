import React, { useEffect, useRef, useState } from 'react'
  import logo from '../assets/total_company_logo_2.png'
  import {SunIcon } from '@heroicons/react/24/solid'
  import { MoonIcon } from '@heroicons/react/24/solid'
  import {Bars3Icon} from '@heroicons/react/24/solid'
  import logo2 from '../assets/total_logo.png'
  import {useNavigate, useLocation } from 'react-router-dom'
import { LanguageContext } from './LanguageContext'
import { useContext } from 'react'


  const HomeNav = () => {
    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const { language, setLanguage } = useContext(LanguageContext); // Use LanguageContext

  const getText = (key) => {
    return translations[language][key];
  };
    const [isDarkMode, setIsDarkMode] = useState(() => {
      const storedDarkMode = localStorage.getItem('darkMode');
      return storedDarkMode ? JSON.parse(storedDarkMode) : false;
    });
    const translations = {
      English: {
        totalAI: "Total AI",
        contact: "Contact",
        termsOfService: "Terms Of Service",
        privacyPolicy: "Privacy Policy",
      },
      Arabic: {
        totalAI: "إجمالي الذكاء الاصطناعي",
        contact: "اتصل",
        termsOfService: "شروط الخدمة",
        privacyPolicy: "سياسة خصوصية",
      },
    };

    const navigate = useNavigate();
    const location = useLocation();

    const handleNewPageClick = () => {
      navigate('/totalai');
    };

    const handleNewPageClickHome = () => {
      navigate('/');
    };

    const toggleSideMenu = () => {
      setSideMenuOpen(!sideMenuOpen);
    };

    const handlePrivacyPolicyClick = () => {
      navigate('/privacypolicy');
    };

    const handleTermsOfServiceClick = () => {
      navigate('/termsofservice');
    };

    const setDarkMode = () => {
      const newDarkMode = !isDarkMode;
    
      if (newDarkMode) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    
      setIsDarkMode(newDarkMode);
      localStorage.setItem('darkMode', JSON.stringify(newDarkMode));
    };

    useEffect(() => {
      if (isDarkMode) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    }, [isDarkMode]);

    const toggleContact = () => {
      localStorage.setItem("openModalOnHomepage", "true");
      navigate("/");
      setSideMenuOpen(!sideMenuOpen);
    };
    

    return (
      <div className="bg-gradient-to-t from-white via-white to-purple-200 dark:bg-gradient-to-t dark:from-zinc-950 dark:via-neutral-950 dark:to-blue-950">
        <div class="flex justify-between items-center w-full p-2 bg-gray-50  dark:bg-neutral-900 shadow-2x">
          <div class="flex-1 justify-center items-center flex">
            <button
              class="w-9 hover:animate-spin"
              onClick={() => setDarkMode()}
            >
              <div className=" active:scale-125 ease-in-out duration-150">
                {isDarkMode ? (
                  <MoonIcon color="#7e22ce" className="dark:fill-blue-700" />
                ) : (
                  <SunIcon color="#7e22ce" />
                )}
              </div>
            </button>
          </div>
          <div class="flex-1 justify-center items-center flex">
            <button onClick={handleNewPageClickHome}>
              <img src={logo} class="w-16 h-auto dark:invert" alt="test" />
            </button>
          </div>
          <div class="flex-1 justify-center items-center flex">
            <button
              class=" w-9 mr-8 focus:outline-none hover:animate-bounce active:animate-ping"
              onClick={toggleSideMenu}
              aria-label="Toggle side menu"
            >
              <Bars3Icon color="#7e22ce" className="dark:fill-blue-700" />
            </button>
          </div>
          <div
            className={`z-10 fixed top-0 right-0 h-full w-80  bg-gradient-to-tl from-violet-400 via-purple-200 to-white transform transition-transform duration-300 flex flex-col justify-between shadow-2xl dark:bg-gradient-to-tl dark:from-blue-950 dark:via-blue-950 dark:to-gray-900 ${
              sideMenuOpen ? " translate-x-0" : " translate-x-full"
            }`}
          >
            <div className="">
              <button onClick={toggleSideMenu}>
                <h1 class=" font-thin ml-8 mt-2 text-neutral-950 dark:text-gray-50">
                  X
                </h1>
              </button>
              <div className=" ml-24 ">
                <button
                  onClick={handleNewPageClick}
                  className="scale-75 w-32 h-32 overflow-hidden relative border border-black rounded-lg shadow-xl active:scale-90 ease-in-out duration-100"
                >
                  <img
                    src={logo2}
                    alt=""
                    style={{ backgroundColor: "#181410" }}
                    className=" object-cover  min-w-full min-h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
                  />
                </button>
              </div>
              <div className=" pl-32">
                <h1 className=" font-semibold text-lg dark:text-white">
                  Total AI
                </h1>
              </div>
            </div>
            <div class="flex flex-col h-48">
              <button onClick={toggleContact}>
                <div class="border-y border-white h-12">
                  <h2 class="text-center mt-2 text-base dark:text-white">
                    {getText("contact")}
                  </h2>
                </div>
              </button>
              <button className="" onClick={handleTermsOfServiceClick}>
                <div class="border-y  border-white h-22">
                  <h2 className="text-center text-base dark:text-white">
                    {getText("termsOfService")}
                  </h2>
                </div>
              </button>
              <button onClick={handlePrivacyPolicyClick}>
                <div class="border-y border-white h-22">
                  <h2 class="text-center text-base dark:text-white">
                    {getText("privacyPolicy")}
                  </h2>
                </div>
              </button>
              <div className="mt-4 ml-2 flex space-x-48">
                <h3
                  className={`text-gray-300 text-base cursor-pointer ${
                    language === "English"
                      ? "border-b-2 border-blue-500"
                      : ""
                  }`}
                  onClick={() => setLanguage("English")}
                >
                  English
                </h3>
                <h3
                  className={`text-gray-300 text-base cursor-pointer ${
                    language === "Arabic"
                      ? "border-b-2 border-blue-500"
                      : ""
                  }`}
                  onClick={() => setLanguage("Arabic")}
                >
                  عربي
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default HomeNav