import React from "react";
import { CCarousel, CCarouselItem, } from "@coreui/react";
import ex2 from '../assets/cbot.png'
import ex3 from '../assets/choose.png'
import ex4 from '../assets/dreamon.png'
import ex5 from '../assets/tare.png'

const DarkCarousel = () => {
  return (
    <CCarousel>
      <CCarouselItem>
        <img className=" h-[500px] rounded-lg" src={ex2} alt="Slide 1" />
      </CCarouselItem>
      <CCarouselItem>
        <img className=" h-[500px] rounded-lg" src={ex3} alt="Slide 2" />
      </CCarouselItem>
      <CCarouselItem>
        <img className=" h-[500px] rounded-lg" src={ex4} alt="Slide 3" />
      </CCarouselItem>
      <CCarouselItem>
        <img className="h-[500px] rounded-lg" src={ex5} alt="Slide 4" />
      </CCarouselItem>
    </CCarousel>
  );
};

export default DarkCarousel;
