import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAndroid, faAppStoreIos } from '@fortawesome/free-brands-svg-icons'

import { motion } from 'framer-motion';
import { LanguageContext } from './LanguageContext';
import { useContext } from 'react';

function TotalAifeatures() {

  const slideRight = {
    hidden: { x: 0, opacity: 0 },
    visible: { x: 50, opacity: 1, transition: { delay: 0.2 } }
  }
  
  const slideUp = {
    hidden: { y: 50, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { delay: 0.2 } }
  }
  
  const slideLeft = {
    hidden: { x: 50, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { delay: 0.2 } }
  }

  const { language } = useContext(LanguageContext);

  const t = translations[language];

  return (
    <motion.div className=' flex flex-row -ml-24' initial="hidden" animate="visible">
        <motion.div className=" md:mx-auto" variants={slideRight}>
          <h1 className=' mt-10 text-orange-300 font-bold '>Total Artificial Intelligence</h1>
          <h2 className=' font-sm md:font-medium font-semibold text-gray-50 text-center pt-10 w-72 md:w-[500px]'> <span className=' text-orange-300'>TotalAI</span> {t.aboutUs}  </h2>
        </motion.div>
    </motion.div>
  )
}

export default TotalAifeatures

export const translations = {
  English: {
    aboutUs: " is an application that is availabe in IOS and Android mobile devices. It has the most of AI's capabilities all in one app.",
  },
  Arabic: {
    aboutUs: " هو تطبيق متاح على أجهزة الجوال التي تعمل بنظامي IOS وأندرويد. يحتوي على معظم قدرات الذكاء الاصطناعي كلها في تطبيق واحد.",
  },
};
