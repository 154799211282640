import React from 'react'
import TotalAifeatures from '../components/TotalAifeatures'
import TotalAifeats from '../components/TotalAifeats'
import TotalAIMessages from '../components/TotalAIMessages'
import Footer from '../components/Footer'

// bg-gradient-to-tr from-[#1B1611] via-[#1B1611] to-[#181410]

function TotalAi() {
  return (
    <div className=' h-max max-lg:-pr-30 overflow-x-hidden' style={{background: 'rgb(27,22,17)',background: "linear-gradient(156deg, rgba(27,22,17,1) 20%, rgba(4,3,3,1) 80%)"}}
    >
      <TotalAifeatures />
      <TotalAifeats />
      <TotalAIMessages />
      <div className="dark:bg-gray-800">
        <Footer />
      </div>
    </div>
  )
}

export default TotalAi