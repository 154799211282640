import React from 'react';
import PrivacyPolicySets from '../components/PrivacyPolicySets';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <div className=' h-screen bg-gradient-to-t from-white via-white to-purple-200 dark:bg-gradient-to-t dark:from-zinc-950 dark:via-neutral-950 dark:to-blue-950 '>
      <PrivacyPolicySets />
      <div className="dark:bg-gray-800">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
