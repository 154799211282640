import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeNav from './components/HomeNav';
import TotalAi from './Pages/TotalAi';
import Home from './Pages/Home';
import PrivacyPolicy from './Pages/PrivacyPolicy'; 
import { LanguageProvider } from './components/LanguageContext'; 
import TermsOfService from './Pages/TermsOfService';

function App() {
  return (
    <LanguageProvider>
      <Router>
        <div className="App bg-neutral-950">
          <HomeNav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/totalai" element={<TotalAi />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsofservice" element={<TermsOfService />} />
          </Routes>
          {/* test */}
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;