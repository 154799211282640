import React, { useState, useEffect } from 'react';
import DarkCarousel from './DarkCarousel';
import { LanguageContext } from './LanguageContext';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

function HomeFeatures() {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [animate, setAnimate] = useState(false);
  const { language } = useContext(LanguageContext);

  const handleScrollToFooter = () => {
    const footer = document.getElementById('roadmap');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
      setActive(false);
    }
    navigate('/');
  };

  const handleNewPageClick = () => {
    navigate('/totalai');
  };

  useEffect(() => {
    setAnimate(true);
  }, []);

  const t = translations[language];

  return (
    <div 
      className={`flex flex-col md:flex-row mx-4 md:mx-32 pt-56 
      ${animate ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'} 
      transition-opacity duration-700 transition-transform duration-700 ease-out`} 
      id='homelanding'>
      <div className='md:w-1/2 '>
        <div className='flex justify-center'>
          <DarkCarousel />
        </div>
      </div>
      <div className='w-full md:w-1/2'>
        <h2
          id='about us'
          className='ml-10 text-3xl font-bold text-purple-700 dark:text-white'
        >
          {t.aboutUs}
        </h2>
        <p className='mt-5 ml-6 text-lg dark:text-white'>
        {t.aboutUsDescription}
        </p>
        <button className=' mt-20 ml-60 animate-bounce hover:animate-none active:scale-110 ease-in-out duration-200' onClick={handleNewPageClick}>
          <h1 className=' text-orange-300'>{t.exploreMore}</h1>
        </button>
      </div>
    </div>
  );
}

export default HomeFeatures;

export const translations = {
  English: {
    aboutUs: "About Us",
    aboutUsDescription: "Total is a name of our group that makes applications such as TotalAI, which is currently our only app, but in the future we'll be developing and publishing more applications, the progress of which will be added to our roadmap",
    exploreMore: "Explore more",
  },
  Arabic: {
    aboutUs: "معلومات عنا",
    aboutUsDescription: "توتال هو اسم مجموعتنا التي تصنع تطبيقات مثل TotalAI، والذي هو حاليًا تطبيقنا الوحيد، لكننا في المستقبل سنطور وننشر المزيد من التطبيقات، وسيتم إضافة التقدم الذي نحرزه إلى خارطة طريقنا",
    exploreMore: "استكشف المزيد",
  },
};
