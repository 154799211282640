import React from 'react'
import HomeNav from '../components/HomeNav'
import HomeFeatures from '../components/HomeFeatures'
import HomeContact from '../components/HomeContact'
import RoadMap from '../components/RoadMap'
import Footer from '../components/Footer'

function Home() {
  return (
    <div className=' h-max bg-gradient-to-t from-white via-white to-purple-200 dark:bg-gradient-to-t dark:from-zinc-950 dark:via-neutral-950 dark:to-blue-950  '>
      <HomeFeatures />
      <HomeContact/>
      <div className=" h-screen bg-gradient-to-t from-violet-400 via-purple-200 to-white dark:bg-gradient-to-t dark:from-blue-950 dark:via-neutral-950 dark:to-zinc-950">
        <RoadMap />
      </div>
      <div className="dark:bg-gray-800">
        <Footer />
      </div>
    </div>
  )
}

export default Home