import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import styles from "./HomeContact.module.css";
import emailjs from "@emailjs/browser";
import "./custom.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { useWindowSize } from "@react-hook/window-size";
import { LanguageContext } from "./LanguageContext";
import { useContext } from "react";

function HomeContact() {
  const [active, setActive] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [showTextInput, setShowTextInput] = useState(false);
  const [width, height] = useWindowSize();

  const { language } = useContext(LanguageContext);

  const t = translations[language];

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const data = Object.fromEntries(formData);

    emailjs
      .send("service_yofi2ke", "template_j1kghyg", data, "iFtzq5jYPrcreQHkM")
      .then(
        (result) => {
          console.log("Email sent successfully:", result.status, result.text);
          window.alert("Email sent successfully!"); // Alert the user
          form.current.reset(); // Reset the form after successful submission
        },
        (error) => {
          console.log("Failed to send email:", error);
          window.alert("Failed to send email."); // Alert the user
        }
      );
  };


  const handleClick = () => {
    setActive(!active);
  };

  const handleButtonClick = (id) => {
    setSelectedButton(id);
  };

  const handleScrollToFooter = () => {
    const footer = document.getElementById("roadmap");
    if (footer) {
      footer.scrollIntoView({ behavior: "smooth" });
      setActive(false);
    }
  };

  useEffect(() => {
    const openModal = localStorage.getItem("openModalOnHomepage");
    if (openModal === "true") {
      setActive(true);
      localStorage.removeItem("openModalOnHomepage");
    }
  }, []);

  return (
    <div>
      <div className={styles.buttonContainer}>
        <button
          onClick={handleClick}
          className="w-10 h-10 sm:w-12 sm:h-12 sticky left-4 sm:left-16"
        >
          <div className="shadow-black rounded-full h-full hover:ease-in duration-200 hover:scale-125 hover:animate-pulse active:opacity-30 dark:invert">
            <ChatBubbleOvalLeftIcon color="#000" />
          </div>
        </button>
      </div>
      {/* //test */}
      {width <= 765 ? (
        <div
          className={`${styles.modalContainer} ${active ? "" : styles.hidden} ${styles.modalContainer} ${active ? "" : "hidden"}`}
        >
          <div
            className={`${styles.modal} dark:bg-gray-900 ml-72 ${
              styles.modalLeft
            } -mr-4 ${active ? styles.active : ""}`}
          >
            <h1 className="flex justify-center text-purple-700 mt-6 dark:text-indigo-300">
              {t.contactUs}
            </h1>
            {width <= 765 ? (
              <></>
            ) : (
              <>
                <h2 className="flex text-xl ml-12 dark:text-white ">
                  {" "}
                  {t.problem}
                </h2>
                <div className="flex flex-row justify-between mt-4 ml-16">
                  <button
                    className={`p-2 mb-2 border border-gray-300  ease-in duration-200 ${
                      selectedButton === 1 ? "bg-purple-200" : "bg-white "
                    } rounded`}
                    onClick={() => handleButtonClick(1)}
                  >
                    {t.bugs}
                  </button>
                  <button
                    className={`p-2 mb-2 border border-gray-300  ease-in duration-200 ${
                      selectedButton === 2 ? "bg-purple-200" : "bg-white"
                    } rounded`}
                    onClick={() => handleButtonClick(2)}
                  >
                    {t.photos}
                  </button>
                  <button
                    className={`p-2 mb-2 mr-16 ease-in border border-gray-300 duration-200 ${
                      selectedButton === 3 ? "bg-purple-200" : "bg-white"
                    } rounded`}
                    onClick={() => handleButtonClick(3)}
                  >
                    {t.security}
                  </button>
                </div>
                <div className="flex flex-row justify-between mt-4 ml-16">
                  <button
                    className={`p-2 mb-2 mr-16 ease-in border border-gray-300 duration-200 ${
                      selectedButton === 4 ? "bg-purple-200" : "bg-white"
                    } rounded`}
                    onClick={() => handleButtonClick(4)}
                  >
                    {t.device}
                  </button>
                  <button
                    className={`p-2 mb-2 mr-20 ease-in border border-gray-300 duration-200 ${
                      selectedButton === 5 ? "bg-purple-200" : "bg-white"
                    } rounded`}
                    onClick={() => handleButtonClick(5)}
                  >
                    {t.payments}
                  </button>
                  <button
                    className={`p-2 mb-2 ease-in border mr-20 border-gray-300 duration-200 ${
                      selectedButton === 6 ? "bg-purple-200" : "bg-white "
                    } rounded`}
                    onClick={() => handleButtonClick(6)}
                  >
                    {t.other}
                  </button>
                </div>
              </>
            )}

            <button
              onClick={handleClick}
              className=" ml-80 hover:scale-105 active:scale-90 duration-75 ease-in-out"
            >
              <h1 className="dark:text-gray-100 text-black font-bold">X</h1>
            </button>
            <form className="flex flex-col" onSubmit={handleSubmit} ref={form}>
              <div className="ml-14">
                <p className="mt-4 mb-2 dark:text-gray-50">{t.name}</p>
                <input
                  type="text"
                  name="user_name"
                  placeholder="Enter your name"
                  className="border-b-2 w-56 text-lg pl-2  dark:text-gray-50 focus:outline-purple-500 ease-out duration-1000 dark:bg-gray-800 dark:rounded-sm"
                />
              </div>
              <div className="ml-14 mt-2">
                <p className="mb-3 dark:text-gray-50">{t.email}</p>
                <input
                  type="email"
                  name="user_email"
                  placeholder="Enter your email"
                  className="border-b-2 w-56 text-lg pl-2 dark:text-gray-50  focus:outline-purple-500 ease-out duration-1000 dark:bg-gray-800 dark:rounded-sm "
                />
              </div>
              <div className="ml-14 mt-1">
                <p className="mb-3 dark:text-gray-50">{t.message}</p>
                <textarea
                  name="message"
                  placeholder="Enter your message"
                  className="border-b-2  w-56 h-16 pl-2 dark:text-gray-50 text-lg focus:outline-purple-500 ease-out duration-1000 resize-none dark:bg-gray-800 dark:rounded-sm"
                />
              </div>

              <input
                type="submit"
                className="w-24 text-lg ml-[280px] hover:bg-purple-200 active:bg-purple-50 ease-in duration-200 rounded-lg -mt-10"
                value={t.send}
              />
            </form>
          </div>

          <div
            className={`${styles.modal} dark:bg-indigo-900 mr-72 ${
              styles.modalRight
            } ${active ? styles.active : ""}`}
          >
            <h1 className=" text-gray-100 justify-center mt-6 flex">
              {t.coming}
            </h1>
            <button
              onClick={handleScrollToFooter}
              className=" flex justify-center mx-auto mt-10 text-xl border-2 pt-3 rounded-full animate-border-color h-16 w-48 active:scale-90 duration-200 text-gray-100"
            >
              {t.map}
            </button>
            <div className="flex justify-center">
              <h2 className=" mt-10 text-white">{t.platforms}</h2>
              <div className="absolute mt-20 -ml-24">
                <button className=" hover:scale-110 active:scale-90 ease-in-out duration-200">
                  <a
                    className=" text-base dark:text-white decoration-0 no-underline"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/totalai0fficial/"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size="3x"
                      color="#fff"
                    />
                  </a>
                </button>
              </div>
              <div className="absolute mt-20 ml-24">
                <button className="hover:scale-110 active:scale-90 ease-in-out duration-200">
                  <a
                    className=" text-base dark:text-white decoration-0 no-underline"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.tiktok.com/@totalaiofficial"
                  >
                    <FontAwesomeIcon icon={faTiktok} size="3x" color="#fff" />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${styles.modalContainer} ${active ? "" : styles.hidden}`}
        >
          <div
            className={`${styles.modal} dark:bg-gray-900 ml-72 ${
              styles.modalLeft
            } -mr-4 ${active ? styles.active : ""}`}
          >
            <h1 className="flex justify-center text-purple-700 mt-6 dark:text-indigo-300">
              {t.contactUs}
            </h1>
            {width <= 765 ? (
              <></>
            ) : (
              <>
                <h2 className="flex text-xl ml-12 dark:text-white ">
                  {" "}
                  {t.problem}
                </h2>
                <div className="flex flex-row justify-between mt-4 ml-16">
                  <button
                    className={`p-2 mb-2 border border-gray-300  ease-in duration-200 ${
                      selectedButton === 1 ? "bg-purple-200" : "bg-white "
                    } rounded`}
                    onClick={() => handleButtonClick(1)}
                  >
                    {t.bugs}
                  </button>
                  <button
                    className={`p-2 mb-2 border border-gray-300  ease-in duration-200 ${
                      selectedButton === 2 ? "bg-purple-200" : "bg-white"
                    } rounded`}
                    onClick={() => handleButtonClick(2)}
                  >
                    {t.payments}
                  </button>
                  <button
                    className={`p-2 mb-2 mr-16 ease-in border border-gray-300 duration-200 ${
                      selectedButton === 3 ? "bg-purple-200" : "bg-white"
                    } rounded`}
                    onClick={() => handleButtonClick(3)}
                  >
                    {t.security}
                  </button>
                </div>
                <div className="flex flex-row justify-between mt-4 ml-16">
                  <button
                    className={`p-2 mb-2 mr-16 ease-in border border-gray-300 duration-200 ${
                      selectedButton === 4 ? "bg-purple-200" : "bg-white"
                    } rounded`}
                    onClick={() => handleButtonClick(4)}
                  >
                    {t.photos}
                  </button>
                  <button
                    className={`p-2 mb-2 mr-20 ease-in border border-gray-300 duration-200 ${
                      selectedButton === 5 ? "bg-purple-200" : "bg-white"
                    } rounded`}
                    onClick={() => handleButtonClick(5)}
                  >
                    {t.device}
                  </button>
                  <button
                    className={`p-2 mb-2 ease-in border mr-20 border-gray-300 duration-200 ${
                      selectedButton === 6 ? "bg-purple-200" : "bg-white "
                    } rounded`}
                    onClick={() => handleButtonClick(6)}
                  >
                    {t.other}
                  </button>
                </div>
              </>
            )}
            <form className="flex flex-col" onSubmit={handleSubmit} ref={form}>
              <div className="ml-14">
                <p className="mt-4 mb-2 dark:text-gray-50">{t.name}</p>
                <input
                  type="text"
                  name="user_name"
                  placeholder="Enter your name"
                  className="border-b-2 w-96 text-lg pl-2  dark:text-gray-50 focus:outline-purple-500 ease-out duration-1000 dark:bg-gray-800 dark:rounded-sm"
                />
              </div>
              <div className="ml-14 mt-2">
                <p className="mb-3 dark:text-gray-50">{t.email}</p>
                <input
                  type="email"
                  name="user_email"
                  placeholder="Enter your email"
                  className="border-b-2 w-96 text-lg pl-2 dark:text-gray-50  focus:outline-purple-500 ease-out duration-1000 dark:bg-gray-800 dark:rounded-sm"
                />
              </div>
              <div className="ml-14 mt-1">
                <p className="mb-3 dark:text-gray-50">{t.message}</p>
                <textarea
                  name="message"
                  placeholder="Enter your message"
                  className="border-b-2  w-96 h-16 pl-2 dark:text-gray-50 text-lg focus:outline-purple-500 ease-out duration-1000 resize-none dark:bg-gray-800 dark:rounded-sm"
                />
              </div>

              <input
                type="submit"
                className="w-24 text-lg ml-[450px] hover:bg-purple-200 active:bg-purple-50 ease-in duration-200 rounded-lg -mt-10"
                value={t.send}
              />
            </form>
          </div>

          <div
            className={`${styles.modal} dark:bg-indigo-900 mr-72 ${
              styles.modalRight
            } ${active ? styles.active : ""}`}
          >
            <button
              onClick={handleClick}
              className=" ml-[550px] hover:scale-105 active:scale-90 duration-75 ease-in-out"
            >
              <h1 className=" text-white font-bold">X</h1>
            </button>
            <h1 className=" text-gray-100 justify-center mt-6 flex">
              {t.coming}
            </h1>
            <button
              onClick={handleScrollToFooter}
              className=" flex justify-center ml-48 mt-10 text-xl border-2 pt-3 rounded-full animate-border-color h-16 w-48 active:scale-90 duration-200 text-gray-100"
            >
              {t.map}
            </button>
            <div className="flex justify-center">
              <h2 className=" mt-40 text-white">{t.platforms}</h2>
              <div className="absolute mt-56 -ml-24">
                <button className=" hover:scale-110 active:scale-90 ease-in-out duration-200">
                  <a
                    className=" text-base dark:text-white decoration-0 no-underline"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/totalaiofficial/"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size="3x"
                      color="#fff"
                    />
                  </a>
                </button>
              </div>
              <div className="absolute mt-56 ml-24">
                <button className="hover:scale-110 active:scale-90 ease-in-out duration-200">
                  <a
                    className=" text-base dark:text-white decoration-0 no-underline"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.tiktok.com/@totalaiofficial"
                  >
                    <FontAwesomeIcon icon={faTiktok} size="3x" color="#fff" />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeContact;
export const translations = {
  English: {
    contactUs: "Contact Us",
    problem: "Select The Problem",
    bugs: "Bugs",
    payments: "Payments",
    security: "Account Security",
    photos: "Images",
    device: "Devices",
    other:"other",
    name: "Name",
    email:"Email",
    message:"Message",
    coming:" What's Coming?",
    map: "Our RoadMap",
    platforms: "Find us on Other Platforms",
    send: "send",
    },
  Arabic: {
    contactUs:"اتصل بنا",
    problem: "اختر المشكلة ",
    bugs:"أخطاء",
    payments: "الدفع",
    security: "أمان الحساب",
    photos: "صور",
    device: "اجهزة",
    other:"آخر",
    name:"ٰٰألاسم",
    email:"البريد الإلكتروني",
    message:"رسالة",
    coming:"ما الذي سيأتي؟",
    map: "خارطة طريقنا",
    platforms: "ابحث عنا في المنصات الأخرى",
    send: "ارسل"
  },
};
