import React, { useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import logo from "../assets/total_company_logo.png";


import ghibli1 from "../assets/1.jpg";
import ghibli2 from "../assets/2.jpg";
import joker from "../assets/3.jpg";
import pat from "../assets/4.jpg";
import female from "../assets/5.jpg";
import walt from "../assets/6.jpg";
import Vid1 from "../assets/totalaiad.mov";
import Gif2 from "../assets/women.gif";
import Gif3 from "../assets/patrick.gif";
import Gif4 from "../assets/ballerina.gif";
import Gif5 from "../assets/ttt.gif";
import astro from "../assets/astro.jpeg";
import pink from "../assets/pinkpath.jpeg";
import purple from "../assets/purplepath.jpeg";
import angel from "../assets/roboticangel.jpeg";
import cosmic from "../assets/cosmic.jpeg";
import man from "../assets/hoodie.jpeg";
import jap from "../assets/japan.jpeg";
import lonely from "../assets/lonelypath.jpeg";
import marki from "../assets/marki.png";
import don from "../assets/dondon.png";
import kidgoku from "../assets/gokuu.png";
import dif from "../assets/dif.png";
import "./NPC.css";
import sound1 from '../assets/vegeta.mp3';
import sound2 from '../assets/Mark.mp3';
import sound3 from '../assets/do.mp3';
import sound4 from '../assets/lol.mp3';
import sound5 from '../assets/lol2.mp3';
import sound6 from '../assets/lol3.mp3';
import rock from '../assets/rock.png'
import jazz from '../assets/jazzy.png'
import chill from '../assets/elec.png'
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid, faAppStoreIos } from "@fortawesome/free-brands-svg-icons";
import { useContext } from "react";
import { LanguageContext } from "./LanguageContext";

const slideInFromLeft = {
  hidden: { x: -100, opacity: 0 },
  visible: { x: 0, opacity: 10 },
};

const slideInFromRight = {
  hidden: { x: 100, opacity: 0 },
  visible: { x: 0, opacity: 1 },
};


function TotalAIMessages() {
  const [refLeft, inViewLeft] = useInView({ threshold: 0.1 });
  const [refRight, inViewRight] = useInView({ threshold: 0.1 });
  const audio1 = new Audio(sound1);
  const audio2 = new Audio(sound2);
  const audio3 = new Audio(sound3);
  const audio4 = new Audio(sound4);
  const audio5 = new Audio(sound5);
  const audio6 = new Audio(sound6);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const playSound1 = () => {
    audio1.play();
  };

  const playSound2 = () => {
    audio2.play();
  };

  const playSound3 = () => {
    audio3.play();
  };

  const playSound4 = () => {
    audio4.play();
  };

  const playSound5 = () => {
    audio5.play();
  };

  const playSound6 = () => {
    audio6.play();
  };

  const FIRST_IMAGE = {
    imageUrl: 'https://media.discordapp.net/attachments/1058878537899180184/1176572166704218212/IMG_8940.jpg?ex=656f5b38&is=655ce638&hm=53b98596ce475afb0a2ce400459a586806686349c81976945545d6637265029b&=&width=992&height=992'
  };
  const SECOND_IMAGE = {
    imageUrl: 'https://media.discordapp.net/attachments/1058878537899180184/1176572166347689984/my_image.jpg?ex=656f5b38&is=655ce638&hm=dccade3882839948f0db2119eaaf7eab52970303ddd3d726c293258618c3d5b9&=&width=720&height=720'
  };

  const { language } = useContext(LanguageContext);

  const t = translations[language];

  return (
    <div className="md:ml-12 overflow-x-hidden ">
      <div className="flex flex-row mt-10 ml-10">
       
      </div>
      <div className=" flex flex-row md:mt-0 mt-10 ml-10 md:ml-0">
        
      </div>
      <div className="flex flex-col  md:mt-48 mt-20 md:ml-[550px]">
        <h1 className=" text-gray-200 font-bold text-3xl md:text-5xl max-lg:text-center ">
          {t.soon}
        </h1>
        <motion.h1
          ref={refRight}
          className=" text-orange-300 mt-6 text-3xl"
          variants={slideInFromRight}
          initial="hidden"
          animate={inViewRight ? "visible" : "hidden"}
        >
          <span className=" text-white">●</span> {t.video}
        </motion.h1>
      </div>
      <div className="flex flex-col mt-5 items-center md:mt-50 max-lg:-ml-8 md:-ml-14 md:mb-32 md:mx-auto">
      <video
      src={Vid1} 
      alt="Total AI Template"
      className="rounded-3xl w-[300px] md:w-[800px]"
      loop
      muted
      autoPlay
      playsInline
    />
        <div className="">
          <h1 className="glowing-text ml-12 text-orange-300 mt-10">
            {t.ai}
          </h1>
        </div>
        <div className=" mt-10">
          <h2 className=" text-gray-300 text-4xl text-center">
            #1 <span className=" text-orange-300"> Avatar</span>
          </h2>
          <h2 className=" text-gray-300 text-center text-lg md:text-2xl mx-10">
            {t.avatar}
          </h2>
        </div>
        <div className=" flex flex-row space-x-7 mt-11 mx-14 md:mx-0">
          <img
            src={Gif2}
            alt="Total AI Template"
            className=" rounded-3xl w-full h-[200px] md:h-[300px]"
          />
          <img
            src={Gif3}
            alt="Total AI Template"
            className=" rounded-3xl w-full h-[200px] md:h-[300px]"
          />
        </div>
        <div className=" mt-44">
          <h2 className=" text-gray-300 text-4xl text-center">
            #2 <span className=" text-orange-300"> Dream</span>
          </h2>
          <h2 className=" text-gray-300 text-center text-base md:text-2xl">
            {t.dream}
          </h2>
        </div>
        <div className="flex flex-row space-x-1 md:space-x-7 mt-11 mx-20 md:mx-0">
          <button className="hover:scale-75 md:hover:scale-110 ease-in-out duration-200 active:scale-50 md:active:scale-105 hidden md:block"
            onClick={() => {
              handleImageClick(lonely)
            }}
          >
            <img
              src={lonely}
              alt="Total AI Template"
              className="rounded-3xl w-full h-[100px] md:h-[300px]"
            />
          </button>
          <button className="hover:scale-75 md:hover:scale-110 ease-in-out duration-200 active:scale-50 md:active:scale-105"
            onClick={() => {
              handleImageClick(angel)
            }}
          >
            <img
              src={angel}
              alt="Total AI Template"
              className="rounded-3xl w-full h-[100px] md:h-[300px]"
            />
          </button>
          <button className="hover:scale-75 md:hover:scale-110 ease-in-out duration-200 active:scale-50 md:active:scale-105"
            onClick={() => {
              handleImageClick(man)
            }}
          >
            <img
              src={man}
              alt="Total AI Template"
              className="rounded-3xl w-full h-[100px] md:h-[300px]"
            />
          </button>
          <button className="hover:scale-75 md:hover:scale-110 ease-in-out duration-200 active:scale-50 md:active:scale-105 hidden md:block"
            onClick={() => {
              handleImageClick(astro)
            }}
          >
            <img
              src={astro}
              alt="Total AI Template"
              className="rounded-3xl w-full h-[100px] md:h-[300px]"
            />
          </button>
        </div>
        <div className="flex flex-row space-x-1 md:space-x-7 mt-11 mx-10 md:mx-0">
          <button className="hover:scale-75 md:hover:scale-110 ease-in-out duration-200 active:scale-50 md:active:scale-105 hidden md:block"
            onClick={() => {
              handleImageClick(jap)
            }}
          >
            <img
              src={jap}
              alt="Total AI Template"
              className="rounded-3xl w-full h-[100px] md:h-[300px]"
            />
          </button>
          <button className="hover:scale-110 ease-in-out duration-200 active:scale-105"
            onClick={() => {
              handleImageClick(cosmic)
            }}
          >
            <img
              src={cosmic}
              alt="Total AI Template"
              className="rounded-3xl w-full h-[100px] md:h-[300px]"
            />
          </button>
          <button className="hover:scale-110 ease-in-out duration-200 active:scale-105"
            onClick={() => {
              handleImageClick(purple)
            }}
          >
            <img
              src={purple}
              alt="Total AI Template"
              className="rounded-3xl w-full h-[100px] md:h-[300px]"
            />
          </button>
          <button className="hover:scale-110 ease-in-out duration-200 active:scale-105"
            onClick={() => {
              handleImageClick(pink)
            }}
          >
            <img
              src={pink}
              alt="Total AI Template"
              className="rounded-3xl w-full h-[100px] md:h-[300px]"
            />
          </button>
        </div>
        {selectedImage && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center z-50">
            <img src={selectedImage} alt="Selected" className="max-w-full max-h-full" />
            <button className="absolute top-5 right-5 text-orange-300 hover:scale-105 ease-in active:scale-100" onClick={handleClose}>Close</button>
          </div>
        )}

        <div className=" mt-44">
          <h2 className=" text-gray-300 text-4xl text-center">
            #3<span className=" text-orange-300"> Videos</span>
          </h2>
        </div>
        <div className=" flex flex-row space-x-7 mt-11 mx-10 md:mx-0">
          <img
            src={Gif4}
            alt="Total AI Template"
            className=" rounded-3xl w-full h-[150px] md:h-[300px]"
          />
          <img
            src={Gif5}
            alt="Total AI Template"
            className=" rounded-3xl w-full h-[150px] md:h-[300px]"
          />
        </div>

        <div className=" mt-44">
          <h2 className=" text-gray-300 text-4xl text-center">
            #4 <span className=" text-orange-300">Voices</span>
          </h2>
        </div>

        <div className=" flex flex-row space-x-1 md:space-x-7 mt-11 mx-10 md:mx-0">
          <button className="hover:scale-110 w-full ease-out duration-200 active:scale-105"
            onClick={playSound1}
          >
            <img
              src={kidgoku}
              alt="Total AI Template"
              className="rounded-3xl w-full h-[120px] md:h-[300px]"
            />
          </button>
          <button className="hover:scale-110 w-full ease-out duration-200 active:scale-105"
            onClick={playSound3}
          >
            <img
              src={don}
              alt="Total AI Template"
              className="rounded-3xl w-full h-[120px] md:h-[300px]"
            />
          </button>
          <button className="hover:scale-110 w-full ease-out duration-200 active:scale-105"
            onClick={playSound2}
          >
            <img
              src={marki}
              alt="Total AI Template"
              className="rounded-3xl w-full  h-[120px] md:h-[300px]"
            />
          </button>
        </div>
        <div>
          <div className=" mt-44">
            <h2 className=" text-gray-300 text-4xl text-center">
              #5 <span className=" text-orange-300"> Quality</span>
            </h2>
            <h2 className=" text-gray-300 text-center md:text-2xl text-lg">
              {t.quality}
            </h2>
          </div>
          <ReactBeforeSliderComponent
            firstImage={FIRST_IMAGE}
            secondImage={SECOND_IMAGE}
          />
        </div>
        <div className=" mt-44">
          <h2 className=" text-gray-300 text-4xl text-center">
            #6<span className=" text-orange-300"> Chat</span>
          </h2>
          <h2 className=" text-gray-300 text-center md:text-2xl text-lg md:mx-0 mx-5">
            {t.chat}
          </h2>
        </div>
        <img
          src={dif}
          alt="Total AI Template"
          className=" mt-24 rounded-3xl w-[300px] md:w-[800px]"
        />
        <div className=" mt-44">
          <h2 className=" text-gray-300 text-4xl text-center">
            #7<span className=" text-orange-300"> music</span>
          </h2>
          <h2 className=" text-gray-300 text-center text-lg md:text-2xl">
            {t.music}
          </h2>

          <div className="flex flex-row md:mx-0 -space-x-7 md:space-x-7 mt-11">
            <button className="scale-75 ml-7 md:scale-100 hover:scale-75 md:hover:scale-110 ease-in-out duration-200 active:scale-50 md:active:scale-105"
              onClick={playSound4}
            >
              <img
                src={rock}
                alt="Total AI Template"
                className="rounded-3xl w-full h-[125px] md:h-[300px]"
              />
              <h1 className=" text-gray-100">"rock music"</h1>
            </button>
            <button className="scale-75 md:scale-100 hover:scale-75 md:hover:scale-110 ease-in-out duration-200 active:scale-50 md:active:scale-105"
              onClick={playSound6}
            >
              <img
                src={chill}
                alt="Total AI Template"
                className="rounded-3xl w-full h-[125px] md:h-[300px]"
              />
              <h1 className=" text-gray-100"> "chill typebeat"</h1>
            </button>
            <button className="scale-75 md:scale-100 hover:scale-75 md:hover:scale-110 ease-in-out duration-200 active:scale-50 md:active:scale-105"
              onClick={playSound5}
            >
              <img
                src={jazz}
                alt="Total AI Template"
                className="rounded-3xl w-full h-[125px] md:h-[300px]"
              />
              <h1 className=" text-gray-100">"chill jazz music"</h1>
            </button>
          </div>
        </div>
        <h1 className=" mt-16 text-gray-300 text-lg md:text-2xl mx-5 text-center">{t.music2}</h1>
        <h1 className=" text-gray-300 mt-2 text-lg md:text-2xl">{t.music3}</h1>
      </div>
      <div className="flex flex-row md:mt-80 mt-32 max-lg:-ml-8 md:-ml-14 md:mb-32 md:mx-auto">
        <div className="flex ml-16 flex-col w-1/2">
          <h1 className=" underline decoration-orange-300 text-gray-200">
            Exclusive
          </h1>
          <h1 className="  text-gray-200 mt-20 text-3xl max-lg:text-xl">
            Exclusive:{" "}
            <span className=" text-orange-300">
              {t.exc}
            </span>{" "}
          </h1>
          <h1 className="  text-gray-200 mt-6 text-3xl max-lg:text-xl">
            Exclusive +:{" "}
            <span className=" text-orange-300">
              {t.excps}
            </span>{" "}
          </h1>
          <h1 className="  text-gray-200 mt-6 text-3xl max-lg:text-xl max-lg:mb-10">
            Exclusive Chat:{" "}
            <span className=" text-orange-300">
              {t.excchat}
            </span>{" "}
          </h1>
        </div>
        <div className=" flex-wrap flex md:w-1/2 flex-row">
          <img
            src={ghibli1}
            alt=""
            className=" md:h-52 h-40 md:w-52 w-[400px] md:mt-10 rounded-md ml-12 "
          />
          <img
            src={ghibli2}
            alt=""
            className=" md:h-52 h-40 md:w-52 w-40 md:mt-10 -mt-10 rounded-md ml-12 hidden md:block"
          />
          <img
            src={joker}
            alt=""
            className=" md:h-52 h-40 md:w-52 w-40 md:mt-10 -mt-10 rounded-md ml-12 "
          />
          <img
            src={pat}
            alt=""
            className=" md:h-52 h-40 md:w-52 w-40 md:mt-10 -mt-10 rounded-md ml-12 hidden md:block"
          />
          <img
            src={female}
            alt=""
            className=" md:h-52 h-40 md:w-52 w-40 md:mt-10 -mt-10 rounded-md ml-12 "
          />
          <img
            src={walt}
            alt=""
            className=" md:h-52 h-40 md:w-52 w-40 md:mt-10 -mt-10 rounded-md ml-12 hidden md:block"
          />
          <motion.div className="md:pt-10 mt-40 -ml-[1000px] " >
        <h1 className=' text-orange-300 font-bold md:ml-0 max-lg:w-56 max-lg:text-3xl'>{t.download}</h1>
        <div className="flex flex-row md:mt-20 mt-5 -ml-20">
        <button 
          className='hover:scale-110 active:scale-90 ease-in-out duration-200 '
          onClick={() => window.open('https://apps.apple.com/us/app/totalai/id6449304084', '_blank')}
          >
          <FontAwesomeIcon icon={faAppStoreIos} size="3x" color='#FFB74D' />
        </button>
          <h2 className=' text-gray-50 pl-2 md:pl-4 md:font-medium font-sm'>AppStore</h2>

        <button 
        className='hover:scale-110 active:scale-90 ease-in-out duration-200 '
        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.total.totalai', '_blank')}
      >
        <FontAwesomeIcon icon={faAndroid} size="3x" color='#FFB74D' />
      </button>
        <h2 className=' text-gray-50 md:pl-4 md:font-medium font-sm'>PlayStore</h2>
      </div>
      </motion.div>
        </div>
      </div>
    </div>
  );
}

export default TotalAIMessages;

export const translations = {
  English: {
    soon:"More AI Models Coming Soon",
    video:"Video to Video Generation",
    ai: "7 AI Models in One APP",
    avatar: "Transform your photos to the selected styles",
    dream: "Dream Has 4 Ai features! Generate images/voices/videos/music with a prompt",
    quality: "Upscale your images & restore them", 
    chat: "Interact with GPT-3.5 turbo & GPT-4 (GPT3.5 turbo is for free)",
    music: "Generate any kinds of music you like",
    music2: "Generate music up to 30 seconds for free users",
    music3: "90 seconds for exclusive users",
    exc: "4.99$ weekly for every features WITHOUT GPT 4",
    excps:"7.49$ weekly for every features includes GPT4 & text to video",
    excchat:"3.99$ Weekly for only GPT-4",
    download:"Download Now"
  },
  Arabic: {
    soon: "المزيد من نماذج الذكاء الاصطناعي قريبًا",
    video:"توليد فيديو إلى فيديو",
    ai: "٧ نماذج للذكاء الاصطناعي في تطبيق واحد",
    avatar: "حوّل صورك إلى الأنماط المختارة",
    dream: "يحتوي على 4 ميزات للذكاء الاصطناعي! قم بتوليد الصور/الأصوات/الفيديوهات/الموسيقى بمجرد إدخال مطلب",
    quality:"قم بتحسين جودة صورك واستعادتها",
    chat: "تفاعل مع GPT-3.5 تيربو و GPT-4 (GPT-3.5 تيربو متاح مجانًا)",
    music: "قم بتوليد أي نوع من الموسيقى تفضله",
    music2: "قم بتوليد موسيقى لمدة تصل إلى ٣٠ ثانية للمستخدمين المجانيين" ,
    music3: " ٩٠ ثانية للمستخدمين الحصريين",
    exc:  " GPT-4  دولار4.99 أسبوعيًا لكل الميزات باستثنا ",
    excps: " دولار7.49 أسبوعيًا لكل الميزات بما في ذلك GPT4 وتحويل النص إلى فيديو",
    excchat:"3.99 دولار أسبوعيًا لـ GPT-4 فقط",
    download:"حملها الآن",
  },
};