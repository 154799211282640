import React, { useContext } from 'react'
import Roadmap from '../assets/roadmap.png'
import { LanguageContext } from './LanguageContext';

function RoadMap() {

  const { language } = useContext(LanguageContext);

  const t = translations[language];
  return (
    <div className="flex flex-col mt-80 sm:mt-96 ">
      <h1
        id="roadmap"
        className="flex justify-center mt-8 sm:mt-16 font-bold text-neutral-950 dark:text-gray-50"
      >
        {t.road}
      </h1>
      <div className="mt-36 sm:mt-28 mx-auto">
        <img
          src={Roadmap}
          className="h-auto w-full sm:h-[670px] sm:w-[1200px] dark:invert"
          alt=""
        />
      </div>
    </div>
  );
}


export default RoadMap

export const translations = {
  English: {
    road:"RoadMap",
  },
  Arabic: {
    road:"خارطة الطريق",
  },
};